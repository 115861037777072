import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/hero-image"

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: 50vw;
  max-width: ${props => `${props.maxWidth}px` || "auto"};
  flex-shrink: 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    width: calc(100% - 16px);
    padding-top: 20px 8px;
  }
`

const TitleBlock = styled.div`
  margin-right: 60px;
  padding-bottom: 10px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div`
  margin-bottom: 20px;
  padding-left: calc(50% - 530px);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 16px;
  }
`

const LightBlueSpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightCol};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin: 16px 0;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.lightText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        garagentorantriebe: file(name: { eq: "torantrieb-magic-innen-g60-0211" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100, cropFocus: NORTH) {
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "magic" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/DSC_2280|DSC_2313|HIG_7398|Normstahl_IT0005/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
      <Helmet>
        <link rel="canonical" href="https://normstahl.com/de-de/torantriebe/garagentorantriebe" />
      </Helmet>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>


            <ProductDescriptionBlock>
              <Flex direction={"column"}>
                <TitleBlock>
                  <h1>GARAGENTOR<wbr />ANTRIEBE</h1>
                  <p>
                    Mit unseren Normstahl Garagentorantrieben haben Sie es in der Hand: Einfach sitzen bleiben und das Tor fährt per Knopfdruck nach oben – und das ausgesprochen schnell: Mit 210 mm/Sekunde (Leerlauf) beim Öffnen gehört der Normstahl Magic zu den Schnellsten am Markt. Doch auch bei Sicherheit und Energiesparen bestechen unsere Antriebe mit Top-Leistungen...
                    <br />
                    <br />
                    <ul>
                      <li>Top-Komfort dank der leistungsstarken Garagentorantriebe mit Funkbedienung, Funkcodetaster bzw. Schlüsseltaster (Aufputz-/Unterputz-Montage) – automatisch Öffnen und Schließen egal bei welchem Wetter!</li>
                      {/*
                      <li>Die extrem robusten Schiebetorantriebe meistern bis zu zwei Tonnen Masse und bewegen mit ihrer Schubkraft max. 30 Meter Laufweg vom Fleck.</li>
                      */}
                      <li>Maximale Sicherheit inklusive: Unbefugtes Öffnen von Außen nahezu unmöglich durch automatische Tor-Sperrung (selbsthemmendes Getriebe.)
                      Die Funkfernsteuerung mit 866 MHz und 128-Bit AES Verschlüsselung ist für niemanden zu entschlüsseln. Kinder werden dank der optional erhältlichen Lichtschranken, die beim geringsten Hindernis den automatischen Tor-Rücklauf aktivieren geschützt.</li>
                      <li>Nur 2 Watt Ruhestrom im Standby-Modus (50% Ersparnis gegenüber dem Vorgänger Modell)</li>
                      <li>2 Jahre Normstahl Garantie auf Elektronik und 5 Jahre auf Mechanik</li>
                    </ul>
                  </p>
                </TitleBlock>
                <ImageWrapper>
                <HeroImage
                  fluid={props.garagentorantriebe.childImageSharp.fluid}
                  alt={"Drehtorantrieb"}
                />
                </ImageWrapper>
              </Flex>
            </ProductDescriptionBlock>

          <LightBlueSpan>
            <Margins>
              <p>
                Normstahl Garagentorantriebe - Komfortabel, verlässlich und
                sicher!
                <br />
                Normstahl-Garagentore lassen sich ganz bequem mit unseren
                Garagentorantrieben bedienen.
              </p>
            </Margins>
          </LightBlueSpan>

          <BoxComponent>
            <Margins>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <Title>
                      Ihr elektrischer Garagentoröffner erwartet Sie schon.
                    </Title>
                    <p>
                      Ein Normstahl Garagentorantrieb ist nicht nur komfortabel.
                      Auch wenn es um Sicherheit geht, können Sie sich auf Ihren
                      Toröffner hundertprozentig verlassen. Ein selbsthemmendes
                      Getriebe verhindert unbefugtes Öffnen von außen mit einer
                      automatischen Sperrung. Der Code Ihres Handsenders ist
                      durch eine 128-Bit AES Verschlüsselung gesichert. Diese
                      ist nach heutigem Stand der Technik nicht angreifbar und
                      wird weltweit für Verschlüsselungen hochsensibler Daten
                      eingesetzt. Und sollten Ihre Kinder in der Garage spielen,
                      müssen Sie sich ebenfalls keine Sorgen machen. Ihr
                      elektrischer Garagentoröffner leitet mit seiner
                      intelligenten Elektronik beim geringsten Hindernis sofort
                      den Tor-Rücklauf ein. Dass so viel Perfektion auch noch
                      gut aussieht, wird Ihnen erst recht gefallen.
                    </p>
                    <Title> Es kann so einfach sein</Title>
                    <p>
                      Viele Eigenheimbesitzer achten sehr auf
                      Wirtschaftlichkeit, installieren die neueste Haustechnik
                      und schätzen modernen Komfort. Warum dann aber die Garage
                      außen vor lassen? Ein Normstahl Toröffner bietet Ihnen
                      alle Bequemlichkeit, wie sie zur zeitgemäßen Mobilität
                      einfach dazugehört. Der kleine Handsender lässt sich
                      leicht verstauen und bei Bedarf äußerst unkompliziert
                      bedienen. Ein Druck aufs Knöpfchen – schon tritt der
                      Garagentorantrieb kraftvoll und dennoch nahezu lautlos in
                      Aktion. Selbst bei schönstem Sonnenschein gibt es dann für
                      Sie keinen Grund mehr, nur wegen des Garagentores auf den
                      letzten Metern der Heimfahrt umständlich aus und wieder
                      einzusteigen. Übrigens können Sie Ihr Tor schon öffnen,
                      wenn Sie noch ein gutes Stück entfernt sind und bei der
                      Gelegenheit auch gleich die Beleuchtung für die Auffahrt
                      einschalten.
                    </p>
                    <a href="https://normstahl.com/de-de/torantriebe/garagentorantriebe/" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                </LowerBox>
              </LowerBoxWrapper>

              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>Magic 600 Torantrieb</Title>
                    <p>
                      Kraftvoll, schnell und nahezu geräuschlos öffnet der
                      Garagentorantrieb Normstahl Magic 600 Ihr Garagentor - und
                      Sie bleiben nach einem langen Arbeitstag ganz entspannt
                      sitzen.
                    </p>
                    <a href="https://normstahl.com/de-de/torantriebe/garagentorantriebe/magic600" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="magic 600"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>Magic 1000 Torantrieb</Title>
                    <p>
                      Stark - Wenn der Garagentorantrieb Normstahl Magic 1000
                      seine "Muskeln" spielen lässt, gehen selbst schwere Tore
                      spielerisch auf und zu. 1000 N Anzugskraft sind einfach
                      bärenstark.
                    </p>
                    <a href="https://normstahl.com/de-de/torantriebe/garagentorantriebe/magic1000" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="magic 1000"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
            </Margins>
          </BoxComponent>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
